import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import { css } from "@emotion/react";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { html, frontmatter } = markdownRemark;
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <div dangerouslySetInnerHTML={{ __html: html }} css={MarkdownStyle} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;

const MarkdownStyle = css`
  max-width: 1024px;
  padding: 50px 50px 100px 50px;

  h2 {
    margin-bottom: 20px;
    font-size: 36px;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
  }

  > p {
    margin-left: 20px;
  }
  > ol,
  ul {
    margin-left: 50px;
  }
  p,
  li {
    line-height: 24px;
  }

  blockquote {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  > blockquote {
    margin-left: 40px;
  }

  table {
    border-collapse: collapse;
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
    td,
    th {
      border: 1px solid black;
      padding: 10px;
    }
  }
`;
